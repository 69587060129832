<template>
  <div class="row m-0 justify-content-center holder cursor mb-3" @mouseover="mouseOver" @mouseleave="mouseLeave" :class="[size, {hover: isHover}]" @click="goToProject">
    <div class="col-12 p-0 relative">
      <div class="img_holder" ref="theImage">
        <Image :imageName="project.imageUrl" :width="theImageWidth" :height="theImageHeight" />
      </div>
      <div class="overlay"></div>
      <div class="row m-0 h-100 above">
        <div class="col-12 p-3 font12 bold mb-2 align-self-start">
          <div class="tab">
            {{project.projectType.name}}
          </div>
        </div>
        <div class="col-12 p-3 mb-2 align-self-end ">
          <div class="row m-0">
            <div class="col-10 p-0">
              <div class="row m-0">
                <div class="col-12 p-0 featured_title text-uppercase">
                  {{project.name}}
                </div>
                <!-- <div class="col-12 p-0 date">
                  {{date}}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Featured Projects',
  props: ['project', 'size'],
  components: {
    Image: defineAsyncComponent(() => import('@/components/Image.vue'))
  },
  data () {
    return {
      isHover: false,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  computed: {
    date () {
      const date = DateTime.fromMillis(this.project.lastModified)
      return date.toLocaleString(DateTime.DATE_FULL)
    }
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    mouseOver () {
      this.isHover = true
    },
    mouseLeave () {
      this.isHover = false
    },
    goToProject () {
      window.scroll(0, 0)
      this.$router.push('/project/' + this.project.id)
    }
  }
}
</script>

<style scoped>
.holder {
  height: 400px;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, rgba(0,0,0,0.75) 100%);
  /* transition: .5s cubic-bezier(.4, .7, .04, .88);
  transition-property: opacity,transform,filter; */
  z-index: 1;
  border-radius: 15px;
}
.overlay {
  position: absolute;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, rgba(0,0,0,0.75) 100%);
  /* transition: .5s cubic-bezier(.4, .7, .04, .88);
  transition-property: opacity,transform,filter; */
  z-index: -1;
  width: 100%;
  height: 100%;
}
.holder.small {
  height: calc(200px - 0.5rem);
}
.img_holder {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .75;
}
.img_holder img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  animation: 0.3s zoomOut;
}
.hover .img_holder img {
  object-fit: cover;
  width: 110%;
  height: 110%;
  object-position: 55% 55%;
  animation: 0.3s zoomIn;
}
@keyframes zoomIn {
  0% {
    opacity: 1;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
  }
  100% {
    opacity: 1;
    width: 110%;
    height: 110%;
    object-position: 55% 55%;
  }
}
@keyframes zoomOut {
  100% {
    opacity: 1;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
  }
  0% {
    opacity: 1;
    width: 110%;
    height: 110%;
    object-position: 55% 55%;
  }
}
.tab {
  border-radius: 1rem;
  font-weight: 500;
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border: 2px solid transparent;
  background-color: rgb(32, 63, 37);
  color: #fff;
  width: max-content;
  text-transform: uppercase;
}
.featured_title {
  font-weight: 500;
  font-family: "quicksand_medium", Sans-serif;
  color: #fff;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.date {
  color: var(--gold-color);
  font-size: 13px;
  font-weight: 500;
  font-family: "quicksand_medium", Sans-serif;
  text-transform: uppercase;
}
.above {
  z-index: 2;
}
</style>
